import React from 'react';

import Layout from "../layouts/site/layout";

function Contact() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-16">
                <h2 className="text-red-1000 font-bold text-base">Contact us</h2>
                <p className="mt-2">If you have any questions or queries we are more than happy to answer them. Please do not hesitate to contact us.</p>
                <div className="grid grid-cols-3 mt-4">
                    <div className="col-span-1">
                        <p><span className="text-red-1000 font-bold mr-1">Tel:</span>+44 (0) 1302 325906</p>
                        <p><span className="text-red-1000 font-bold mr-1">Fax:</span>+44 (0) 1302 760511</p>
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-4">
                            <div className="col-span-1">
                                <p className="text-red-1000 font-bold mt-2">Our Location is:</p>
                            </div>
                            <div className="col-span-3">
                                <p className="leading-tight ml-2">MSI-Quality Forgings Ltd. <br />Balby Carr Bank <br />Doncaster <br />DN4 8DH <br />England</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </Layout>
    </div>
  );
}

export default Contact;
